<template>
  <div id="filtroInvestimentos" class="opportunity-filter">
    <div class="item">
      <b-navbar-nav  class="m-auto">
        <b-nav-item-dropdown id="filters-city"  text="Cidade" v-model="filter.city">
          <b-dropdown-item v-for="item in cities" v-bind:key="item" @click="setCity(item)">
            {{ item }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </div>

    <div class="item datepicker">
      <b-navbar-nav>
        <b-nav-item-dropdown id="dropdown-form" ref="dropdownDelivery" text="Entrega">
          <b-dropdown-form>
              <b-form-group b-form-group label="De" label-for="dropdown-form-de">
                <b-form-input type="date" locale="pt-BR" id="rentabilityRange" v-model="filter.ofDate" />
              </b-form-group>

              <b-form-group label="Até" label-for="dropdown-form-ate">
                <b-form-input type="date" locale="pt-BR" id="rentabilityRange" v-model="filter.untilDate" />
              </b-form-group>

              <b-button variant="secondary" size="sm" @click="cancelDelivery()">CANCELAR</b-button>&nbsp&nbsp
              <b-button variant="primary" size="sm" @click="setDelivery()">OK</b-button>
          </b-dropdown-form>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </div>

    <div class="item ddlValue">
      <b-navbar-nav class="m-auto">
        <b-nav-item-dropdown ref="dropdownValue" text="Valor">
          <b-dropdown-form>
            <b-form-group label="De" label-for="dropdown-form-end">
              <b-input-group label="De" prepend="R$" class="dropdown-form-end">
                <b-form-input ref='moneyofValue' class="calc-text-value" min="0.01" v-model.lazy="filter.ofValue" v-money="money" />
              </b-input-group>
            </b-form-group>

            <b-form-group label="Até" label-for="dropdown-form-end">
              <b-input-group label="Até" prepend="R$" class="dropdown-form-end">
                <b-form-input ref='moneyuntilValue' class="calc-text-value" min="0.01" v-model.lazy="filter.untilValue" v-money="money" />
              </b-input-group>
            </b-form-group>

            <b-button variant="secondary" size="sm" @click="cancelValue()">CANCELAR</b-button>&nbsp&nbsp
            <b-button variant="primary" size="sm" @click="setValue()">OK</b-button>
          </b-dropdown-form>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </div>

    <div class="item">
      <b-navbar-nav class="m-auto">
        <b-nav-item-dropdown text="Construtora" size="lg">
          <b-dropdown-item v-for="company in companies" v-bind:key="company.id" @click="setCompany(company)">
            {{ company.name }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </div>

    <div style="clear:both" />

    <div class="badgesCompany">
      <div v-for="company in filterConstructionCompanies" class="pillItem">{{ company }} <span><b-icon-x @click="removeCompany(company)" /></span></div>
    </div>
  </div>
</template>

<script lang="ts">
import { BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownForm, BFormGroup, BFormInput, BButton, BInputGroup } from 'bootstrap-vue'
import { VMoney } from 'v-money'

export default {
  name: 'FilterOpportunityComponent',
  startDate: null,
  endDate: null,
  isCloseable: false,

  components: {
    BNavbarNav,
    BDropdownForm,
    BFormGroup,
    BDropdownItem,
    BNavItemDropdown,
    BButton,
    BFormInput,
    BInputGroup,
  },
  directives: { money: VMoney },
  emits:['input'],
  props: {
    item: null,
    value: null,
    cities: null,
    companies: null,
  },

  data() {
    return {
      filterConstructionCompanies: [],
      filter: {
        city: '',
        ofDate: null,
        untilDate: null,
        ofValue: null,
        untilValue: null,
        constructionCompanyIds: [],
        skipCount: 0,
        maxResultCount: 6,
      },
      price: 0,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  
  methods: {
    setCompany(company) {
      this.filterConstructionCompanies.push(company.name)
      this.filter.constructionCompanyId = company.id
      this.update()
    },
    setCity(id) {
      this.filter.city = id
      this.update()
    },
    update() {
      this.$emit('input', this.filter)
      console.log(this.filter)
    },
    setDelivery() {
      this.closeDelivery()
      this.update()
    },
    cancelDelivery() {
      this.filter.ofDate = null
      this.filter.untilDate = null
      this.closeDelivery()
      this.update()
    },
    closeDelivery() {
      this.isCloseable = true
      this.$refs.dropdownDelivery.hide()
    },
    cancelValue() {
      this.filter.ofValue = null
      this.filter.untilValue = null
      this.$refs.dropdownValue.hide()
    },
    setValue() {
      this.update()
      this.$refs.dropdownValue.hide()
    },
    removeCompany(company) {
      const filtered = this.filterConstructionCompanies.filter(x => x.name === company)
      this.filterConstructionCompanies.splice(filtered, 1)
      this.filter.constructionCompanyId = undefined
      this.update()
    },
  },
}
</script>

<style>

#filtroInvestimentos .navbar-nav .dropdown-menu  {
    overflow: auto;
    height: 20em;
}

.badgesCompany {
  float: right;
}
.badgesCompany .pillItem {
  color: #353535;
  border: 1px solid #C0C0C0;
  border-radius: 15px;
  padding: 1px 5px 1px 5px;
  font-size: small;
  margin: 5px;
}

.badgesCompany .pillItem span {
  cursor: pointer;
}

.badgesCompany .pillItem span:hover {
  color: red !important;
}

.opportunity-filter {
  border-radius: 12px;
}
.opportunity-filter .item {
  margin: 0px 5px 0px 5px ;
  border-radius: 5px;
  padding: 5px 20px 5px 20px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.03);
  font-size: small;
  font-weight: 700;
  text-transform: capitalize;
  transition: 0.3s;
  width: auto;
  float: left;
}
.opportunity-filter .item:hover {
  background-color: #E9E9E9;;
}

.opportunity-filter .item a {
  Color:black !important;
}

.datepicker .dropdown-menu {
  width: 220px;
}

.ddlValue .dropdown-menu {
  width: 180px;
}

</style>
