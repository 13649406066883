<template>
  <div>
    <!-- banner -->
    <ImageOpportunity text="Conheça nossas oportunidades de Investimento!" style="margin-bottom: 60px" />

    <b-overlay
      variant="white"
      :show="isLoadingOpenedOpportunities && isLoadingClosedOpportunities"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    />

    <!-- main content -->
    <div class="site-container">
      <div class="group-container">
        <div class="group-title">
          <div class="title">{{ openedOpportunities.title }}</div>
          <div class="menu">
            <FilterOpportunityComponent v-model="filterOpened" :cities="cities" :companies="companies"  @input="loadOPenedFilter"/>
          </div>
        </div>
        <div class="d-card-group" v-if="openedOpportunities.opportunities.length > 0">
          <CardOpportunityComponent v-for="item in openedOpportunities.opportunities" :key="item.idImmobile" :item="item" :solded="false" />
        </div>
        <div class="card-button" v-if="openedOpportunities.opportunities.length > 0">
          <button v-if="filterOpened.skipCount > 0" @click="loadMinorOpened()">Carregar menos</button>
          <button :disabled="!isMoreOpened" @click="loadMoreOpened()">Carregar mais</button>
        </div>
      </div>

      <div class="group-container" v-if="closedOpportunities.opportunities.length > 0">
        <div class="group-title">
          <div class="title">{{ closedOpportunities.title }}</div>
          <div class="menu">
            <FilterOpportunityComponent v-model="filterClosed" :cities="cities" :companies="companies" @input="loadOPenedFilter" />
          </div>
        </div>
        <div class="d-card-group">
          <CardOpportunityComponent v-for="item in closedOpportunities.opportunities" :key="item.idImmobile" :item="item" :solded="true" />
        </div>
        <div class="card-button">
          <button @click="loadMoreClosed()">Carregar mais</button>
        </div>
      </div>

      <div class="group-container" v-if="soldedOpportunities.opportunities.length > 0">
        <div class="group-title">
          <div class="title">{{ soldedOpportunities.title }}</div>
          <div class="menu">
            <FilterOpportunityComponent :cities="cities" :companies="companies"  @input="loadOPenedFilter"/>
          </div>
        </div>
        <div class="d-card-group solded-itens">
          <CardOpportunityComponent v-for="item in soldedOpportunities.opportunities" :key="item.idImmobile" :item="item" :solded="true" />
        </div>
        <div class="card-button">
          <button @click="loadMoreSolded()">Carregar mais</button>
        </div>
      </div>

    </div>

    <ImageCalcInvest />
    <div class="investment site-item">
      <CalcInvestmentComponent @onSimulate="simulate" v-model="dataCalcInvestment" />
    </div>

    <div class="site-item">
      <newsletter-card />
    </div>
    <!-- footer -->
    <Footer />
  </div>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import Footer from '../components/parts/footer/Footer.vue'
import ImageOpportunity from '../components/images/ImageOpportunity.vue'
import ImageCalcInvest from '../components/images/ImageCalcInvest.vue'
import NewsletterCard from '../components/NewsletterCard.vue'
import CardOpportunityComponent from '../components/opportunity/CardOpportunity.vue'
import FilterOpportunityComponent from '../components/opportunity/Filter.vue'
import CalcInvestmentComponent from '../components/CalcInvestment.vue'
import OpportunityStore from '../services/OpportunityService'

const maxOppened = 6
const maxClosed = 3

export default {
  name: 'SiteOportunity',

  components: {
    BOverlay,
    ImageOpportunity,
    ImageCalcInvest,
    Footer,
    NewsletterCard,
    CardOpportunityComponent,
    FilterOpportunityComponent,
    CalcInvestmentComponent,
  },

  data() {
    return {
      isLoadingOpenedOpportunities: true,
      isLoadingClosedOpportunities: true,
      isLoadingSoldedOpportunitiess: true,
      isMoreOpened: true,
      isMoreClosed: true,
      isMoreSolded: true,
      filterOpened: {
        skipCount: 0,
        maxResultCount: maxOppened,
      },
      filterClosed: {
        skipCount: 0,
        maxResultCount: maxClosed,
      },
      filterSolded: {
        skipCount: 0,
        maxResultCount: maxClosed,
      },
      openedOpportunities: {
        title: 'Oportunidades Abertas',
        opportunities: [],
      },
      closedOpportunities: {
        title: 'Oportunidades Encerradas',
        opportunities: [],
      },
      soldedOpportunities: {
        title: 'Oportunidades Vendidas',
        opportunities: [],
      },
      cities: [],
      companies: [],
      dataCalcInvestment: {},
    }
  },

  async mounted() {
    await this.loadOPened()
    this.loadMoreClosed()
    this.loadCities()
    this.loadCompanies()
  },

  methods: {
    async loadMinorOpened() {
      this.filterOpened.skipCount -= maxOppened
      await this.loadOPened()
    },
    async loadMoreOpened() {
      this.filterOpened.skipCount += maxOppened
      await this.loadOPened()
    },
    async loadOPened() {
      this.isLoadingOpenedOpportunities = true
      const oppeneds = await OpportunityStore.getOpenedOpportunities(this.filterOpened)
      this.isMoreOpened = oppeneds.data.result.length === maxOppened
      this.openedOpportunities.opportunities = oppeneds.data.result
      this.isLoadingOpenedOpportunities = false
    },
    async loadOPenedFilter(filters){
      this.filterOpened = filters
      await this.loadOPened()
    },

    async loadMoreClosed() {
      this.isLoadingClosedOpportunities = true
      const closed = await OpportunityStore.getClosedOpportunities(this.filterClosed)
      this.isMoreClosed = closed.data.result.length === maxClosed
      if (closed.data.result.length > 0) {
        this.closedOpportunities.opportunities = closed.data.result
      }
      this.isLoadingClosedOpportunities = false
    },
    async loadMoreSolded() {
      this.isLoadingSoldedOpportunities = true
      const solded = await OpportunityStore.getSoldedOpportunities(this.filterSolded)
      this.isMoreSolded = solded.data.result.length === maxClosed
      if (solded.data.result.length > 0) {
        this.soldedOpportunities.opportunities = solded.data.result
      }
      this.isLoadingSoldedOpportunities = false
    },
    async loadCities() {
      const itens = await OpportunityStore.getCities(this.filterOpened)
      this.cities = itens.data.result
    },
    async loadCompanies() {
      const itens = await OpportunityStore.getConstructionCompanies(this.filterOpened)
      this.companies = itens.data.result
    },
    simulate(data) {
      localStorage.setItem('calcInvestment', JSON.stringify(data))
      this.$router.push({ path: '/calc-investment' })
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      return new Intl.DateTimeFormat('pt-BR').format(date)
    },
  },
}
</script>

<style scoped>

.fade {
  animation: fadein 2s;
  -moz-animation: fadein 2s;
  -webkit-animation: fadein 2s;
  -o-animation: fadein 2s;
}

.site .app-content {
  padding: 0 !important;
}
.group-container {
}
.investment {
  margin-top: 80px;
}
.compares {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.site-item {
    max-width: 1200px;
    width: 92%;
    margin: 50px auto;
}
.group-title {
  margin: 30px 0px 30px 0px;
  display: inline-block;
  width: 100%;
}
.group-title .title {
  font-size: x-large;
  font-weight: 800;
  text-transform: capitalize;
  float: left;
}
.group-title .menu {
  float: right;
}
.d-card-group {
  border-radius: 12px;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
}
.d-card-group div {
  margin: 0px 15px 30px 15px;
}
.card-button {
  text-align: center;
  margin: 30px 0px 30px 0px;
}
.card-button button:hover {
  background-color: #E9E9E9;
}
.card-button button {
  padding:15px 30px 15px 30px;
  border-radius: 8px;
  border:  1px solid #888888;
  background-color: white;
  transition: 0.3s;
  margin: 5px;
}

/deep/ .solded-itens .d-card .image {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: .5;
}

@media screen and (min-width: 601) {
  h1 {
    font-size: 2.6rem;
    flex: 1 1 1;
  }
}

@media screen and (max-width: 600) {
  h1 {
    font-size: 1.6rem;
  }
}

h1 {
  margin-bottom: 40px;
  font-weight: 700;
  color: black;
}

h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: 700;
  color: black;
}

h3 {
  margin-bottom: 20px;
  font-size: 1.4rem;
  font-weight: 700;
  color: black;
}

h1,
h2,
h3,
p {
  line-height: 1.3;
}

.scroll-hide p {
  scroll-snap-align: center;
  line-height: 1;
}
.scroll-hide {
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  margin-top: 60px;
}

.scroll-hide p {
  margin-bottom: 0;
}

.scroll-hide::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.por-tras-da-divvy {
  background-size: contain;
  background-repeat: repeat-x;
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}
</style>
